import React from "react";
import Input from "./Input";
class MonthPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value ? new Date(this.props.value) : "",
    };
    this.onMonthPickerChange = this.onMonthPickerChange.bind(this);
  }
  onMonthPickerChange(value) {
    this.props.onChange(value);
    this.setState({ value });
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value
          ? new Date(this.props.value)
          : "",
      });
    }
  }
  render() {
    var { onChange, value, ...newProps } = this.props;
    return (
      <Input
        type="month"
        onChange={this.onMonthPickerChange}
        value={this.state.value}
        {...newProps}
      />
    );
  }
}

export default MonthPicker;
