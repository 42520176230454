import { getAccessToken } from "../../auth/verifyAuth"

async function getPipelines(msalInstance, variables) {
    var accessToken = await getAccessToken(msalInstance, variables);
    return fetch(window.location.origin + "/api/v1.0/pipelines", {
        method: "GET",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        }
    }).then(res => {
        if (res.ok) {
            return res.json()
        }
    })
}

export {
    getPipelines
}
