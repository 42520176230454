import { Fragment, useContext, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useNavigate } from "react-router";
import { XMarkIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { withTranslation } from 'react-i18next';
import { initCompletion } from '../../utils/requests/completions';
import { CompletionsContext, LoadingContext } from '../../App';
import { MsalInstanceContext, VariablesContext } from '../../auth/AppProvider';


function ModalMoreInformationForm({ t, open, setOpen, form, type }) {
  let navigate = useNavigate();
  const [completions, setCompletions] = useContext(CompletionsContext);
  const msalInstance = useContext(MsalInstanceContext);
  const variables = useContext(VariablesContext);
  const [loading, setLoading] = useContext(LoadingContext)

  function postulate() {
    setLoading(true)
    initCompletion(msalInstance, variables, form.id).then(res => {
      if (res.ok) {
        return res.json()
      }
    }).then(deal => {
      if (deal) {
        var completionsLoc = Object.assign([], completions);
        var index = completionsLoc.findIndex(c => c.id === form.id)
        var completion = deal.candidature
        completion.finished = false;
        completion.hs_pipeline_stage = form?.pipeline?.start
        completionsLoc[index] = completion
        setCompletions(completionsLoc)
        navigate("/" + type.name + "/" + form.id);
      }
    }).catch(err => {
    })

  }

  return loading ? null : <Transition.Root show={open} as={Fragment}>
    <Dialog as="div" className="relative z-50" onClose={setOpen}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 sm:p-0 ">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="bg-white relative overflow-hidden">
              <div className="absolute right-0 top-0 pr-8 pt-2 lg:pr-4 block z-40">
                <button
                  type="button"
                  className="modal-close-tab text-gray-600 py-2 px-2 rounded-full hover:text-gray-900 focus:outline-none"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-7 w-7" />
                </button>
              </div>
              <div className='mt-3 pt-12 xl:pl-12 pb-3 mx-12 xl:ml-7 xl:pr-32'>
                <h1 className=" text-3xl font-bold tracking-tight sm:text-4xl text-black z-20 relative" >
                  <span className="text-underline">{form.parameters?.find(pa => pa.name === "name")?.value}</span>
                </h1>
              </div>
              <div className="mx-auto xl:max-w-2xl  xl:max-w-7xl xl:px-12 xl:ml-4 pb-12 " >


                {/* Product image */}
                <div className=" mt-2 mx-12 xl:mx-0">
                  <div className="flow-root">
                    <ul className="w-fit">
                      {form.parameters?.filter(pa => pa.name !== "description" && pa.name !== "name" && pa.value !== null && pa.value !== "" && pa.display)?.map((param, paramIdx) => (
                        <li key={paramIdx}>
                          <p className="text-sm text-gray-500 text-left pb-4">
                            {param.label}{' '}:{''}
                            <span className="font-medium text-gray-900 ml-2">
                              {param.value}
                            </span>
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {/* <div>
                    <section aria-labelledby="information-heading " className="mb-4 ">
                      <div className="mt-2 space-y-6 " dangerouslySetInnerHTML={{ __html: form.parameters?.find(pa => pa.name === "description")?.value }} />
                    </section>
                  </div> */}
                  <div className="block xl:hidden w-full mb-5 mt-12">
                    <div className="flex items-center rounded-full bg-primary-perso w-full text-center px-4 py-2">
                      <div className="cursor-pointer text-white w-full text-center" onClick={() => { postulate() }} style={{ lineHeight: "18px", fontSize: "18px" }}>
                        {t("Postuler")}
                      </div>
                    </div>
                  </div>
                  <div className="hidden xl:flex w-full justify-end mb-5 bottom-0 left-0 mt-12">
                    <div className="flex items-center btn-slide hover:text-white z-10 " onClick={() => { postulate() }}>
                      <ArrowRightIcon className="h-12 w-12 cursor-pointer rounded-full p-4 mr-2 text-white bg-primary-perso" aria-hidden="true" />
                      <div className="cursor-pointer " style={{ lineHeight: "18px", fontSize: "18px" }}>
                        {t("Postuler")}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
}

export default withTranslation()(ModalMoreInformationForm);