import React from "react";

class TextArea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value ? this.props.value : ""
        }
        this.onInputChange = this.onInputChange.bind(this);
    }
    onInputChange(event) {
        var value = event.target.value;
        this.props.onChange(value)
        this.setState({ value })
    }
    render() {
        var { onChange, rows, className, error, ...newProps } = this.props;
        return (
            <textarea onChange={this.onInputChange} value={this.state.value} {...newProps} rows={rows ? rows : "3"}
                className={error ? "border block w-full sm:text-sm border-red-500 rounded-md px-3 text-red"
                    : "border block w-full sm:text-sm border-gray-300 rounded-md px-3"} />
        )
    }
}

export default TextArea;