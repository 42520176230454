import React, { Suspense, useContext, useEffect, useState } from "react";

// language
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { LoadingComponent } from "./auth/components";
import i18n from "./locale/i18n";

// Router
import Router from "./utils/router/router";

// Cookies acception management
import { getAllCompletions } from "./utils/requests/completions";
import { getPipelines } from "./utils/requests/pipelines";
import { VariablesContext } from "./auth/AppProvider";
import Splasher from "./components/Animation/Splasher";
import PlateformeComingSoon from "./pages/PlateformeComingSoon";


export const LoadingContext = React.createContext();
export const ThemeContext = React.createContext();
export const CompletionsContext = React.createContext();
export const ContactIdContext = React.createContext();
export const HSPipelineContext = React.createContext();
export const TypesContext = React.createContext();


function App({ msalInstance }) {
  const variables = useContext(VariablesContext)


  const [theme, setTheme] = useState(null)
  const [completions, setCompletions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [contactId, setContactId] = useState(null);
  const [hspipelines, setHSPipelines] = useState(null);
  const [types, setTypes] = useState(null);


  useEffect(() => {
    getAllCompletions(msalInstance, variables).then(cand => {
      if (cand) {
        setTypes(cand?.typesParameters ? cand.typesParameters.map(t => { t.selected = false; return t; })?.sort((a, b) => a.order > b.order ? 1 : -1) : []);
        setCompletions(cand.candidatures);
        setContactId(cand.contactId);

        setTimeout(() => {
          setLoading(false);
        }, 150);
      }
    }).catch(e => { setLoading(false); });

    fetch("/parameters/theme.json").then(res => {
      if (res.ok) {
        return res.json()
      }
    }
    ).then(them => {
      setTheme(them)
      var link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.head.appendChild(link);
      }
      link.href = "data" + them.favicon.split(";data")[1]
    });

    getPipelines(msalInstance, variables).then(pipelines => { setHSPipelines(pipelines?.results) });
  }, [msalInstance, variables])

  return <div className="h-screen overflow-hidden">
    <Splasher isLoading={loading} />
    {theme && completions ? <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <Suspense fallback={<LoadingComponent />}>
          <ThemeContext.Provider value={theme}>
            <CompletionsContext.Provider value={[completions, setCompletions]}>
              <ContactIdContext.Provider value={contactId}>
                <HSPipelineContext.Provider value={hspipelines}>
                  <TypesContext.Provider value={[types, setTypes]}>
                    <LoadingContext.Provider value={[loading, setLoading]}>
                      <div className="h-screen overflow-y-auto">
                        <Router />
                      </div>
                    </LoadingContext.Provider>
                  </TypesContext.Provider>
                </HSPipelineContext.Provider>
              </ContactIdContext.Provider>
            </CompletionsContext.Provider>
          </ThemeContext.Provider>
        </Suspense>
      </BrowserRouter>
    </I18nextProvider> : <PlateformeComingSoon />}
  </div>
}

export default App;



