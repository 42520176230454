import React from 'react';
import "./splasher.css"
import { LoadingComponent } from '../../auth/components';

function Splasher({isLoading}){
    return <div className={`${!isLoading ? 'loading-end' : 'loading '}`}>
        <LoadingComponent/>
    </div>
};

export default Splasher;