import React from "react";


import { withTranslation } from "react-i18next";
import { Button } from "../catalyst-component/button";

function Paybox({ t, question }) {


    return question.value === "paid" ? <div className="mt-4 mx-4 px-4 py-5 rounded-md bg-green-500">
        <div className="flex items-center justify-between">
            <div className="text-white border border-white rounded-md p-2 w-48 text-center">{t("Paiement successful")}</div>
        </div>
    </div> : (question.value === "waiting" ? <div className="mt-4 mx-4 px-4 py-5 rounded-md bg-yellow-500">
        <div className="flex items-center justify-between">
            <div className="text-white border border-white rounded-md p-2 w-48 text-center">{t("Waiting for paiement")}</div>
        </div>
    </div> : (question.options[3].value === "0" ? <div className="mt-4 mx-4 px-4 py-5 rounded-md bg-green-500">
        <div className="flex items-center justify-between">
            <div className="text-white border border-white rounded-md p-2 w-full text-center">{t("Votre commande est entièrement gratuite ! Aucun paiement n'est requis.")}</div>
        </div>
    </div> : <div className="mt-4 py-5 rounded-md">
        <div className="flex items-center justify-between">
            <form method="POST" action={question.options.find(opt => opt.label === "url")?.value?.split(" ")[0]}>
                {question.options.filter(opt => opt.label !== "url").sort((a, b) => parseFloat(a.displayOrder) - parseFloat(b.displayOrder)).map(opt => <input type="hidden" name={opt.label} value={opt.value} />)}
                <Button type="submit">{t("Procéder au paiement")}</Button>
            </form>
        </div>
        {/* {errors && <div className="mt-2"><HandleErrorsMessage errors={errors} /></div>} */}
    </div>))
}

export default withTranslation()(Paybox);