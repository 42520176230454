import React from "react";
import Dropdown from "./Dropdown";

class DropdownButton extends React.Component {
    render() {
        return (
            <Dropdown
                componentTriggeringDropdown={(show) =>
                    <button className={this.props.classButton ? this.props.classButton : "bg-white w-full mt-1 2xl:mt-0 relative border border-gray-300 rounded-md shadow-sm pl-3 pr-4 2xl:pr-10 py-2 2xl:py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-xs 2xl:text-sm"} style={this.props.styleButton} onClick={(event) => { show(event) }}>
                        <div className="flex justify-between">
                            <span className="block truncate pr-1">
                                {this.props.label ? this.props.label : "Select"}
                            </span>
                            <svg className="h-4 2xl:h-5 ml-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title"
                                aria-describedby="desc" role="img">
                                <path data-name="layer1"
                                    fill="none" stroke="#202020" strokeMiterlimit="10" strokeWidth="2" d="M20 26l11.994 14L44 26"
                                    strokeLinejoin="round" strokeLinecap="round"></path>
                            </svg>
                        </div>
                    </button>}
                {...this.props}>
                {this.props.children}
            </Dropdown>
        )
    }
}

export default DropdownButton;