import React from "react";
import * as Dropdown from "../dropdown/index";
import Checkbox from "../input/Checkbox";
import { withTranslation } from "react-i18next";
// // Component SelectMultiple props
// options : [{"key", "label"}]
// optionDefault
// required
// onChange
// name
// id

class SelectMultiple extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      label: this.getLabel(this.props.keys),
      keys: this.props.keys ? this.props.keys : [],
    };
  }

  getLabel(keys) {
    var label = "";
    var options = this.props.options;

    if (keys && keys.length > 0 && options.find((opt) => opt.key === keys[0])) {
      label += options.find((opt) => opt.key === keys[0]).label;
      var length = keys.length;
      if (length >= 2) {
        label += ", ...";
      }
    }
    return label;
  }

  onChange(key, value) {
    var keys = this.state.keys;
    if (key === "all") {
      if (
        !this.props.options ||
        (this.props.options && keys && keys.length === this.props.options.length)
      ) {
        this.setState({ label: null, keys: [] });
        this.props.onChange([]);
      } else {
        this.setState({
          label: "All",
          keys: this.props.options ? this.props.options.map((opt) => opt.key) : [],
        });
        this.props.onChange(this.props.options ? this.props.options.map((opt) => opt.key) : []);
      }
    } else {
      if (value) {
        keys.push(key);
      } else {
        var newKeys = [];
        keys.forEach((k) => {
          if (k !== key) {
            newKeys.push(k);
          }
        });
        keys = newKeys;
      }
      var label = this.getLabel(keys);
      this.setState({ label, keys });
      this.props.onChange(keys);
    }
  }

  componentDidMount() {
    this.setState({ keys: this.props.keys ? this.props.keys : [] });
    this.setState({ label: this.getLabel(this.props.keys) });

  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ keys: this.props.keys });
      this.setState({ label: this.getLabel(this.props.keys) });
    }
  }

  render() {
    return this.props.options ? (
      <Dropdown.DropdownButton
        label={
          this.state.label !== "" ? this.state.label : this.props.t("Select")
        }
        styleButton={this.props.styleButton}
        {...this.props}
      >
        <div className="overflow-y-auto max-h-screen h-48 2xl:h-60">
          <div
            key="all"
            className="dropdown-item block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-96 "
          >
            <Checkbox
              value={
                this.state.keys
                  ? this.state.keys.length === this.props.options.length
                  : false
              }
              onChange={(value) => this.onChange("all", value)}
            />

            <span className="ml-3">{this.props.t("Select All")}</span>
          </div>
          {this.props.options.map((opt, key) => {
            return (
              <div
                key={key}
                className="dropdown-item block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-96"
              >
                <Checkbox
                  value={
                    this.state.keys
                      ? this.state.keys.includes(opt.key)
                      : false
                  }
                  onChange={(value) => this.onChange(opt.key, value)}
                />
                {opt.render ? (
                  opt.render()
                ) : (
                  <span className="ml-3">{opt.label}</span>
                )}
              </div>
            );
          })}
        </div>
      </Dropdown.DropdownButton>
    ) : (
      ""
    );
  }
}

export default withTranslation()(SelectMultiple);
