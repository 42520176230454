import { getUser } from "../../auth/verifyAuth"

async function SetTrakingHubspot(msalInstance, hsclientid, clientName, formulaire) {
    window.document.title = clientName + " - " + formulaire;
    var _hsq;
    var scripts = Array.from(document.getElementsByTagName("script")).filter(script =>
        script.src === "https://js.hs-scripts.com/" + hsclientid + ".js").length
    if (!scripts) {
        const curentUser = getUser(msalInstance);
        _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', '/' + clientName + "/" + formulaire]);
        _hsq.push(["identify", { email: curentUser.username }]);

        const script = document.createElement('script');
        script.src = "https://js.hs-scripts.com/" + hsclientid + ".js";
        document.body.appendChild(script);
    } else {
        _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', '/' + clientName + "/" + formulaire]);
        _hsq.push(['trackPageView']);
    }
}

export { SetTrakingHubspot }