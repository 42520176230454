import React from "react"
import Dropdown from "./Dropdown"

class DropdownSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      label: this.props.options && this.props.options[0] ? this.props.options[0].label : ""
    }
  }

  render() {
    return (
      <Dropdown
        componentTriggeringDropdown={(show) => (
          <div
            className={this.props.className}
            onClick={(event) => {
              show(event)
            }}
            style={{display: 'flex', alignItems: 'center', whiteSpace: "nowrap" , marginTop: '4px', cursor: 'pointer'}}
          >
            <div className="p-1">
              {this.state.label}
            </div>
            <div className="">
              <svg
              className='w-5'
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
        )}
        {...this.props}
      >
        {this.props.options.map((opt, key) => {
          return (
            <div
              className="dropdown-item block w-40 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              key={key}
              onClick={() => {
                this.setState({ label: opt.label })
                this.props.onChange(opt.key)
              }}
            >
              {opt.label}
            </div>
          )
        })}
      </Dropdown>
    )
  }
}

export default DropdownSelector
