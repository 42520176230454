import React from "react";
import {
    default as BibPhoneInput,
    isValidPhoneNumber
} from 'react-phone-number-input'
import 'react-phone-number-input/style.css'



class PhoneInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value === 0 || (this.props.value && this.props.value !== null) ? this.props.value : "",
        };
        this.onInputChange = this.onInputChange.bind(this);
    }

    onInputChange(value) {
        this.props.onChange(value ? value : "");
        this.setState({ value });
    }

    render() {
        return (
            <div>
                <div className="text-xs data-[disabled]:opacity-50 text-red-600">{this.state.value ? (isValidPhoneNumber(this.state.value) ? undefined : 'Numéro de téléphone invalide') : null}</div>
                <BibPhoneInput
                    placeholder="Enter phone number"
                    value={this.state.value}
                    onChange={this.onInputChange}
                />
            </div>
        );
    }
}

export default PhoneInput;
