import { getAccessToken } from "../../auth/verifyAuth"

async function initCompletion(msalInstance, variables, formId) {
    var accessToken = await getAccessToken(msalInstance,variables);
    return fetch(window.location.origin + "/api/v1.0/init/"+formId, {
        method: "POST",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`,
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    })
}

async function postCompletion(msalInstance, variables, data) {
    var accessToken = await getAccessToken(msalInstance,variables);
    return fetch(window.location.origin + "/api/v1.0/candidatures", {
        method: "POST",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`,
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    })
}

async function postFile(msalInstance, variables, file, formId, dealId, contactId, questionId) {
    var accessToken = await getAccessToken(msalInstance, variables);
    let data = new FormData();
    data.append('file', file);
    data.append('formId', formId);
    data.append('dealId', dealId);
    data.append('contactId', contactId);
    data.append('questionId', questionId);
    return fetch(window.location.origin + "/api/v1.0/uploadFile", {
        method: "POST",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: data
    })
}

async function getAllCompletions(msalInstance, variables) {
    var accessToken = await getAccessToken(msalInstance, variables);
    return fetch(window.location.origin + "/api/v1.0/candidatures", {
        method: "GET",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json()
        }
    })
}

export {
    postCompletion,
    getAllCompletions,
    postFile, 
    initCompletion
}