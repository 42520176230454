import React from "react";

class Switch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value ? this.props.value : false
        }
        this.onSwitchChange = this.onSwitchChange.bind(this);
    }
    onSwitchChange() {
        const value = !this.state.value;
        this.props.onChange(value);
        this.setState({ value });
    }
    render() {
        var { value } = this.props;
        return (
            <button type="button" onClick={this.onSwitchChange} aria-pressed="false" aria-labelledby="renew-headline" className={value ?
                "bg-indigo-600 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                : "bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}>
                <span aria-hidden="true" className={value ?
                    "translate-x-5 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                    : "translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"}></span>
            </button>
        )
    }
}

export default Switch;