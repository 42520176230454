import React from "react";
import { withTranslation } from "react-i18next";

import { useEffect, useState } from "react";



function ComingSoon() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(false);
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 250);

        return () => clearTimeout(timer);

    }, []);


    return <div className={isVisible ? 'my-forms-visible layout h-full overflow-hidden' : 'hidden'}>
        <section aria-labelledby="quick-links-title" className="grid grid-cols-2 gap-6">
            <div className="flex items-center h-[calc(100%_+_30rem)] mr-24">
                <div className="flex-auto">
                    <h1 className="page-title ">
                        De nouveaux formulaires arrivent <span className="text-underline">bientôt !</span>
                    </h1>
                </div>
            </div>
            <div className="relative">
                <div className="absolute -z-1" style={{ right: "40%" }}>
                    <div className="bg-img bg-img-1" />
                    <div className="bg-img bg-img-2" />
                    <div className="bg-img bg-img-3" />
                    <div className="bg-img bg-img-4" />
                </div>
            </div>
        </section>
    </div>

}

export default withTranslation()(ComingSoon);