function configuration(variables) {
  return {
    auth: {
      authority: variables.AuthorityLog,
      clientId: variables.AzureClientID,
      knownAuthorities: [variables.Authority],
      redirectUri: "/",
      postLogoutRedirectUri: "/"
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: true,
    },
  }
};

const loginRequest = {
  scopes: ["openid", "offline_access"],
};

function protectedResources(variables) {
  return {
    api: {
      endpoint: window.location.origin + "/api",
      scopes: [variables.Audience + "/candidature.readwrite"]
    },
  }
};

export { configuration, loginRequest, protectedResources };
