import React, { useEffect, useState } from "react";

import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { configuration, loginRequest } from "./config";

import App from "../App";
import * as Components from "./components";
export const MsalInstanceContext = React.createContext();
export const VariablesContext = React.createContext();

// Component
const AppProvider = () => {
  const [msalInstance, setMsalInstance] = useState(null);
  const [variables, setVariables] = useState(null);

  useEffect(() => {
    fetch("/parameters/variables.json").then(res => {
      if (res.ok) {
        return res.json()
      }
    }
    ).then(variables => {
      setVariables(variables)
      const config = configuration(variables);
      const msalInstanceLocal = new PublicClientApplication(config)
      setMsalInstance(msalInstanceLocal);
    });

  }, [])

  useEffect(() => {
    if (msalInstance) {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
      }
    }
  }, [msalInstance])
  return msalInstance ?
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        errorComponent={Components.ErrorComponent}
        loadingComponent={Components.LoadingComponent}
      >
        {() => {
          return (
            <MsalInstanceContext.Provider value={msalInstance}>
              <VariablesContext.Provider value={variables}>
                <App
                  userName={
                    msalInstance.getAllAccounts().length ? msalInstance.getAllAccounts()[0].name : null
                  }
                  msalInstance={msalInstance}
                />
              </VariablesContext.Provider>
            </MsalInstanceContext.Provider>
          );
        }}
      </MsalAuthenticationTemplate>
    </MsalProvider> : <Components.LoadingComponent />
};

export { AppProvider };
