import React from "react";
import Input from "./Input";

class NumberPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value ? this.props.value : 0,
        };
        this.onNumberPickerChange = this.onNumberPickerChange.bind(this);
    }
    onNumberPickerChange(value) {
        this.props.onChange(value);
        this.setState({ value });
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            this.setState({
                value: this.props.value
                    ? this.props.value
                    : 0,
            });
        }
    }
    render() {
        var { onChange, value, ...newProps } = this.props;
        return (
            <div
                className="flex flex-row h-10 w-24 rounded-lg relative"
            >
                {/* <button onClick={() => this.onNumberPickerChange(this.state.value - 1)}
                    className="font-semibold h-10 w-10 flex rounded focus:outline-none cursor-pointer"
                >
                    <span className="m-auto">-</span>
                </button> */}
                <Input
                    type="number"
                    className="shadow-sm block w-full text-xs 2xl:text-sm rounded-md"
                    onChange={this.onNumberPickerChange}
                    value={this.state.value}
                    {...newProps}
                />
                {/* REFRESH THE PAGE */}
                {/* <button onClick={() => this.onNumberPickerChange(this.state.value + 1)}
                    className="font-semibold h-10 w-10 flex rounded focus:outline-none cursor-pointer"
                >
                    <span className="m-auto">+</span>
                </button> */}
            </div>
        );
    }
}

export default NumberPicker;
