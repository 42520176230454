import React from "react"
class Dropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdown: false,
    }
    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
  }
  show(event) {
    event.preventDefault()
    this.setState({ dropdown: true }, () => {
      document.addEventListener("click", this.hide)
    })
  }

  hide(event) {
    this.setState({ dropdown: false }, () => {
      document.removeEventListener("click", this.hide)
    })
  }

  render() {
    return (
      <div
        className={this.props.className ? this.props.className : "dropdown"}
        disabled={this.props.disabled}
      >
        {this.props.componentTriggeringDropdown(this.show)}
        {this.state.dropdown ? (
          <div className="absolute mt-2 2xl:mt-2 rounded-md bg-white shadow-lg z-10" style={{minWidth:"250px"}}>
            {this.props.children}
          </div>
        ) : null}
      </div>
    )
  }
}

export default Dropdown
