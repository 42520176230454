import React from "react";
import Input from "./Input";
import { range } from "../../../../utils/functions/math";
import { withTranslation } from "react-i18next";

class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value ? this.props.value : "",
      text: null
    };
    this.onSliderChange = this.onSliderChange.bind(this);
  }

  componentDidMount() {
    var value = typeof this.props.value !== "string" ? this.props.value.toString() : this.props.value
    this.handleTextValue(value)
  }

  onSliderChange(value) {
    value = typeof value !== "string" ? value.toString() : value;
    this.props.onChange(value);
    this.setState({ value });
    this.handleTextValue(value);
  }

  handleTextValue(value) {
    if (this.props.labels && this.props.labels.find(l => l.value === value)) {
      this.setState({ text: "( " + this.props.labels.find(l => l.value === value).text + " )" })
    } else {
      this.setState({ text: null })
    }
  }

  render() {
    var { onChange, value, step, t, labels, ...newProps } = this.props;
    if (!step) {
      step = 1;
    }
    return (
      <div className="w-full">
        <div className="flex">
          {t("Value")} : {this.state.value} {this.state.text}
        </div>
        <Input
          type="range"
          className="slider focus:border-indigo-500 block w-full"
          onChange={this.onSliderChange}
          value={this.state.value}
          step={step}
          {...newProps}
          name={this.props.id}
        />
        <div className="flex justify-between mt-2 text-xs text-gray-400">
          {range(this.props.min, this.props.max + step, step).map((value, key) => {
            if (value === this.props.min) {
              return <span className="text-left" key={key}>{value}</span>
            } else if (value === this.props.max) {
              return <span className="text-right" key={key}>{value}</span>
            } else {
              return <span className="text-center" key={key}>{value}</span>
            }
          })}
        </div>
        <div className="flex justify-between mt-2 text-md text-gray-800">
          <div>
            {this.props.mintitle
              ? this.props.mintitle
              : ""}
          </div>
          <div>
            {this.props.maxtitle
              ? this.props.maxtitle
              : ""}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Slider);
