import { loginRequest, protectedResources } from "./config";

function verifyAuth(msalInstance, component) {
    if (msalInstance.getAllAccounts().length) {
        return component;
    }
}

function isUserAuthent(msalInstance) {
    return msalInstance.getAllAccounts() && msalInstance.getAllAccounts().length
}

function getUser(msalInstance) {
    if (msalInstance.getAllAccounts().length) {
        return msalInstance.getAllAccounts()[0];
    }
}

async function getAccessToken(msalInstance, variables) {
    var protectedResourcesc = protectedResources(variables);
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
        var account = accounts[0]
        const response = await msalInstance.acquireTokenSilent({
            scopes: protectedResourcesc.api.scopes,
            account: account
        });

        return response.accessToken;
    } else {
        return null;
    }

}

async function getAccessTokenGraph(msalInstance) {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
        var account = accounts[0]
        const response = await msalInstance.acquireTokenSilent({ ...loginRequest, account: account });
        return response.accessToken;
    } else {
        return null;
    }
}

function logout(msalInstance) {
    msalInstance.logoutRedirect();
}

export {
    verifyAuth,
    getUser,
    logout,
    isUserAuthent,
    getAccessToken,
    getAccessTokenGraph
};