import { getAccessToken } from "../../auth/verifyAuth"

async function getStripeUrl(msalInstance, variables, contactId, dealId, formId) {
    var accessToken = await getAccessToken(msalInstance, variables);
    return fetch(window.location.origin + "/api/v1.0/paiement/stripe/" + formId + "/" + dealId + "/" + contactId, {
        method: "GET",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        }
    }).then(res => {
        if (res.ok) {
            return res.text()
        }
    })
}

async function getStripeStatus(msalInstance, variables, dealId, checkoutId) {
    var accessToken = await getAccessToken(msalInstance, variables);
    return fetch(window.location.origin + "/api/v1.0/paiement/stripe/" + dealId + "/" + checkoutId, {
        method: "GET",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        }
    }).then(res => {
        if (res.ok) {
            return true;
        } else {
            return false;
        }
    }).catch(() => {
        return false;
    })
}

export {
    getStripeUrl,
    getStripeStatus
}
