import React, { useContext, useEffect, useState } from "react";
import { ThemeContext, ContactIdContext } from "../../App"
import { MsalInstanceContext, VariablesContext } from "../../auth/AppProvider";

import { getStripeUrl } from "../../utils/requests/stripe";
import { withTranslation } from "react-i18next";
import HandleErrorsMessage from "../../pages/Completion/HandleErrorsMessage";

function verifyPath(json, path) {
    var exists = true;
    if (path) {
        var steps = path.split('.');
        if (steps.length > 1) {
            steps.forEach(step => {
                if (json && json[step]) {
                    json = json[step];
                } else {
                    exists = false;
                }
            })
        } else {
            if (!json) {
                exists = false;
            }
        }
    } else {
        exists = false;
    }
    return exists;
}

function Stripe({ t, question, dealId, formId }) {
    const variables = useContext(VariablesContext)
    const theme = useContext(ThemeContext);
    const contactId = useContext(ContactIdContext);
    const msalInstance = useContext(MsalInstanceContext);
    const [status, setStatus] = useState(null);
    const [url, setUrl] = useState(null);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        if (question.stripe) {
            setUrl(question.stripe.url);
            setStatus(question.stripe.status)
        }
    }, [question])


    return status === "paid" ? <div className="mt-4 mx-4 px-4 py-5 rounded-md bg-green-500">
        <div className="flex items-center justify-between">
            <div className="text-white">{question.options[0].label}</div>
            <div className="text-white border border-white rounded-md p-2 w-48 text-center">{t("Paiement successful")}</div>
        </div>
    </div> : (status === "waiting" ? <div className="mt-4 mx-4 px-4 py-5 rounded-md bg-yellow-500">
        <div className="flex items-center justify-between">
            <div className="text-white">{question.options[0].label}</div>
            <div className="text-white border border-white rounded-md p-2 w-48 text-center">{t("Waiting for paiement")}</div>
        </div>
    </div> : <div className="mt-4 mx-4 px-4 py-5 rounded-md" style={verifyPath(theme, "secondary_color") ? { backgroundColor: theme.secondary_color } : null}>
        <div className="flex items-center justify-between">
            <div className="text-white">{question.options[0].label}</div>
            <button type="button" onClick={() => {
                if (url) {
                    window.location.href = url;
                }
                else {
                    getStripeUrl(msalInstance, variables, contactId, dealId, formId).then(url => {
                        if (url != null) {
                            window.location.href = url;
                        } else {
                            setErrors(t("An error occured when creating your checkout session. Please try later."))
                        }
                    }).catch(() => {
                        setErrors(t("An error occured when creating your checkout session. Please try later."))
                    });
                }
            }} className="text-white border border-white rounded-md p-2 w-48">{t("Proceed to payment")}</button>
        </div>
        {errors && <div className="mt-2"><HandleErrorsMessage errors={errors} /></div>}
    </div>)
}

export default withTranslation()(Stripe);