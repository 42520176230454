import React from "react";

// INPUT
// * key
// * onChange => (value)
// type = "button", "checkbox", "color", "date", "datetime-local", "email", "file", "number", "image", "month", "password", "radio", "range", "reset", "tel", "time", "url", "week"
// checked (bool)
// "formaction" (for image type)
// disabled
// accept (type = file) = corresponds to the Extension
// max
// min
// maxlength
// minlength
// pattern (regex)
// required
// placeholder
// src (type=image)
// value
// width (type = image)

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value === 0 || (this.props.value && this.props.value !== null) ? this.props.value : "",
    };
    this.onInputChange = this.onInputChange.bind(this);
  }

  onInputChange(event) {
    var value = event.target.value;
    this.props.onChange(value);
    this.setState({ value });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value || this.props.value !== this.state.value) {
      this.setState({ value: this.props.value });
    }
  }

  render() {
    var { onChange, className, type, error, ...newProps } = this.props;
    return (
      <input
        onChange={this.onInputChange}
        type={type ? type : "text"}
        defaultValue={this.state.value}
        value={
          this.props.value === 0 || (this.props.value && this.props.value !== null) ? this.state.value : ""
        }
        className={error ? "border block w-full sm:text-sm border-red-500 rounded-md h-8 px-3 text-red" : "border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3"}
        {...newProps}
      />
    );
  }
}

export default Input;
