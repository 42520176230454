import { useContext, useEffect, useState } from "react";
import FormNewCompletion from "./FormNewCompletion";
import ListNewCompletions from "./ListNewCompletions";
import { CompletionsContext, LoadingContext, TypesContext } from "../../App";
import { FormTitleContext } from "../../layout/Vertical/Global";
import { MsalInstanceContext, VariablesContext } from "../../auth/AppProvider";
import { useNavigate } from "react-router";

function NewCompletion() {
    const [allcompletions,] = useContext(CompletionsContext);
    const [, setFormTitle] = useContext(FormTitleContext);
    const [loading, setLoading] = useContext(LoadingContext)
    const navigate = useNavigate()
    const variables = useContext(VariablesContext)
    // init state
    const [typesParameters,] = useContext(TypesContext)
    const [completions, setCompletions] = useState([]);
    const [type, setType] = useState(null);


    const msalInstance = useContext(MsalInstanceContext);


    useEffect(() => {
        setFormTitle(null);
    }, [variables, msalInstance])

    useEffect(() => {
        setType(typesParameters.find(t => t.selected === true));
        if (!typesParameters) {
            setLoading(false)
        }
    }, [typesParameters])


    useEffect(() => {
        if (allcompletions && allcompletions.length) {
            if (type) {
                var Ncompletions = allcompletions.filter(c => c.typeId === type.id && (!c.dealId && !c.finished))
                if(Ncompletions && Ncompletions.length > 0){
                setCompletions(Ncompletions);
                setLoading(false)}
                else {
                    navigate("/")
                }
            }
        }
    }, [allcompletions, msalInstance, type])

    const [filters, setFilters] = useState([]);
    const [view, setView] = useState(null);

    useEffect(() => { completions?.length <= 1 ? setView("list") : setView("form") }, [completions])

    return loading? null : (view === "form" ? <FormNewCompletion completions={completions} filters={filters} setFilters={(filt) => { setFilters(filt); setView("list"); }} />
        : (view === "list" ? <ListNewCompletions type={type} completions={completions} setFilters={setFilters} filters={filters} setView={setView} /> : null))
}

export default NewCompletion;