import React from "react";
import Input from "./Input";
class DatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value ? this.props.value : "",
            isChangeByUser: false
        }
        this.onDatePickerChange = this.onDatePickerChange.bind(this);
    }

    componentDidMount() {
        var value = this.props.value;
        const re = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
        // const re = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
        if (value.match(re)) {
            // value = this.props.value.slice(6, 10) + "-" + this.props.value.slice(3, 5) + "-" + this.props.value.slice(0, 2)
            this.setState({ value: value ? value : "" });
        } else {
            var date = new Date(this.props.value);
            value = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
            // var d = new Date(value);
            // value = d.getFullYear() + "-" + (d.getMonth() < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
            this.setState({ value: value ? value : "" });
        }
    }

    onDatePickerChange(value) {
        this.setState({ isChangeByUser: true })
        this.props.onChange(value);
        this.setState({ value });
    }
    render() {
        var { onChange, value, ...newProps } = this.props;
        return (
            <Input type="date" onChange={this.onDatePickerChange} value={this.state.value} {...newProps} />
        )
    }
}

export default DatePicker;