function createClass(name, rules) {
    var style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = name + " {" + rules + "}";
    document.getElementsByTagName('head')[0].appendChild(style);
}

function generateRule(theme) {
    var rule = "";
    if (theme.borderRadius) {
        rule += "border-radius : " + theme.borderRadius + " !important; "
    }
    if (theme.padding) {
        rule += "padding : " + theme.padding + " !important; "
    }
    if (theme.backgroundColor) {
        rule += "background-color : " + theme.backgroundColor + " !important; "
    }
    if (theme.color) {
        rule += "color : \"" + theme.color + "\" !important; "
    }
    if (theme.shadow) {
        rule += "box-shadow : " + theme.shadow + " !important; "
    }
    if (theme.size) {
        rule += "font-size : " + theme.size + " !important; "
    }
    if (theme.weight) {
        rule += "font-weight : " + theme.weight + " !important; "
    }
    return rule;
}

async function createClassForHSForm(theme) {
    if (theme) {
        if (theme.color) {
            // createClass(".fn-date-picker td.is-selected .pika-button", generateRule({ backgroundColor: theme.color.primary, color: "#fff" }))
            // createClass(".fn-date-picker td .pika-button:hover", generateRule({ backgroundColor: theme.color.primary }))
            // createClass(".fn-date-picker td.is-today .pika-button", generateRule({ backgroundColor: theme.color.primary, color: "#fff" }))
            createClass("form input[type=submit], form .hs-button", generateRule({ backgroundColor: theme.color.primary }))
            createClass("form input[type=submit]:hover, form input[type=submit]:focus, form .hs-button:hover, form .hs-button:focus", generateRule({ backgroundColor: theme.color.primary }))
        }
        if (theme.text) {
            if (theme.text.title) {
                createClass(".hubspotForm h1", generateRule(theme.text.title))
            }
        }
        if (theme.form) {
            if (theme.form.button) {
                if (theme.form.button.submit) {
                    createClass("form input[type=submit], form .hs-button", generateRule(theme.form.button.submit));
                }

            }
            if (theme.form.input) {
                createClass("form input[type=text], form input[type=email], form input[type=password], form input[type=tel], form input[type=number], form input[type=file], form input[type=date], form select, form textarea", generateRule(theme.form.input));
            }
            if (theme.form.text) {
                if (theme.form.text.label) {
                    createClass("form label", generateRule(theme.form.text.label))
                }
            }
        }
    }

}

export {
    createClassForHSForm,
    createClass
}