import React, { useContext } from "react";
import { verifyAuth } from "../../auth/verifyAuth";
import { Outlet, useRoutes } from "react-router";
import NotFound from "../../pages/NotFound";
import { MsalInstanceContext } from "../../auth/AppProvider";
import { TypesContext } from "../../App";
import SectionHomePage from "../../pages/Home/SectionHomePage";
import FormHomePage from "../../pages/FormHomePage";
import Completion from "../../pages/Completion";
import PlateformeComingSoon from "../../pages/PlateformeComingSoon";
import NewCompletion from "../../pages/Home/NewCompletion";

const Global = React.lazy(() =>
  import("../../layout/" + process.env.REACT_APP_ORIENTATION + "/Global")
);

function HandleAuthent({ msalInstance }) {
  return verifyAuth(msalInstance, <Outlet />);
}

function Router() {
  const msalInstance = useContext(MsalInstanceContext);
  const [typesParameters,] = useContext(TypesContext);
  const routes = [
    {
      path: "/",
      module: "home",
      element: <SectionHomePage />,
      exact: true,
      isMenuIcon: false,
      logo: null,
      name: "Home"
    },
    {
      path: "/newform",
      module: "home",
      element: <NewCompletion />,
      exact: true,
      isMenuIcon: false,
      logo: null,
      name: "Home"
    },
    {
      path:"/comingsoon",
      module: "home",
      element: <PlateformeComingSoon />,
      exact: true,
      isMenuIcon: false,
      logo: null,
      name: "Coming Soon"
    },
    {
      path: "/:id",
      module: "home",
      element: <FormHomePage />,
      exact: true,
      isMenuIcon: false,
      logo: null,
      name: "Home"
    },
    {
      path: "/:id/:returnpaiement",
      module: "home",
      element: <FormHomePage />,
      exact: true,
      isMenuIcon: false,
      logo: null,
      name: "Home"
    }
  ]
  typesParameters.forEach(type => {


    routes.push(
      {
        path: "/" + type.name + "/:id",
        module: type.name,
        element: <Completion />,
        exact: true,
        isMenuIcon: false,
        logo: null,
        name: type.name
      }
    );

    routes.push(
      {
        path: "/" + type.name + "/:id/:checkoutid",
        module: type.name,
        element: <Completion />,
        exact: true,
        isMenuIcon: false,
        logo: null,
        name: type.name
      }
    );
  });
  let el = useRoutes([
    {
      element: <HandleAuthent msalInstance={msalInstance} />,
      children: [
        {
          element: <Global />,
          children: routes
        }
      ]
    },
    {
      path: "*",
      element: <NotFound />
    }
  ]);
  return el;
}

export default Router;
