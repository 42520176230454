import React from "react"
import { withTranslation } from "react-i18next"

import Input from "../input/Input"
import getDataFiltered from "../../../../utils/functions/filteredData"
import * as Dropdown from "../dropdown/index"

// import CardUser from "../specificComponent/cardUser"

// // Component Select props
// options : [{"key", "label"}]
// optionDefault
// required
// onChange
// name
// id

class Select extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      options: this.props.options,
      optionsFiltered: this.props.options,
      label:
        this.props.options &&
          this.props.options.find((opt) => opt.key === this.props.value)
          ? this.props.options.find((opt) => opt.key === this.props.value).label
          : this.props.value,
    }
    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.options !== prevProps.options) {
      this.setState({
        options: this.props.options,
        optionsFiltered: this.props.options,
        label:
          this.props.options &&
            this.props.options.find((opt) => opt.key === this.props.value)
            ? this.props.options.find((opt) => opt.key === this.props.value).label
            : this.props.value
      })
    }
    if (this.props.value !== prevProps.value) {
      this.setState({
        label:
          this.props.options &&
            this.props.options.find((opt) => opt.key === this.props.value)
            ? this.props.options.find((opt) => opt.key === this.props.value).label
            : this.props.value
      })
    }
  }

  show(event) {
    event.preventDefault()
    this.setState({ open: true }, () => {
      document.addEventListener("click", this.hide)
      if (document.getElementById("search-select")) {
        document.getElementById("search-select").focus()
      }
    })
  }

  hide(event) {
    this.setState({ open: false }, () => {
      document.removeEventListener("click", this.hide)
    })
  }

  render() {
    return (
      <>
        {this.props.options ? (
          this.props.options.length > 10 ? (
            <span className="dropdown">
              {this.state.open ? (
                <>
                  <Input
                    id="search-select"
                    className=""
                    style={{ minWidth: "250px", maxWidth: "250px" }}
                    autoFocus
                    onChange={(value) => {
                      this.setState({ optionsFiltered: undefined })
                      var optionsFiltered = getDataFiltered(
                        this.state.options,
                        value
                      )
                      this.setState({ optionsFiltered })
                    }}
                  />
                  <div
                    className="absolute mt-2 rounded-md bg-white shadow-lg z-10"
                    style={{
                      minWidth: "250px",
                      maxHeight: "250px",
                      overflowY: "auto",
                    }}
                  >
                    {this.state.optionsFiltered
                      ? this.state.optionsFiltered.length > 30
                        ? this.state.optionsFiltered
                          .slice(0, 10)
                          .map((opt, key) => {
                            return (
                              <div
                                className="dropdown-item block px-4 py-2 text-xs 2xl:text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                key={key}
                                onClick={() => {
                                  this.props.onChange(opt.key)
                                  this.setState({ label: opt.label })
                                }}
                              >
                                {opt.label}
                              </div>
                            )
                          })
                        : this.state.optionsFiltered.map((opt, key) => {
                          return (
                            <div
                              className="dropdown-item block px-4 py-2 text-xs 2xl:text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                              key={key}
                              onClick={() => {
                                this.props.onChange(opt.key)
                                this.setState({ label: opt.label })
                              }}
                            >
                              {opt.label}
                            </div>
                          )
                        })
                      : this.props.t("No results")}
                  </div>
                </>
              ) : (
                  <button
                    className={this.props.className ? this.props.className : "bg-white w-full relative border border-gray-300 rounded-md shadow-sm pl-3 pr-2 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-xs 2xl:text-sm"}
                    onClick={(event) => {
                      this.show(event);
                    }}
                  >
                    <div className="flex justify-between w-full">
                    <div className="block truncate">
                      {this.state.label
                        ? this.state.label
                        : this.props.t("Select")}
                    </div>
                    <div className="flex items-center pr-2 pointer-events-none">
                      <svg className="h-4 w-4 2xl:h-5 2xl:w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title"
                        aria-describedby="desc" role="img">
                        <path data-name="layer1"
                          fill="none" stroke="#202020" strokeMiterlimit="10" strokeWidth="2" d="M20 26l11.994 14L44 26"
                          strokeLinejoin="round" strokeLinecap="round"></path>
                      </svg>
                    </div>
                    </div>
                  </button>
                )}
            </span>
          ) : (
              <Dropdown.DropdownButton
                label={
                  this.state.label ? this.state.label : this.props.t("Select")
                }
                {...this.props}
              >
                <>
                  {this.props.options.map((opt, key) => {
                    return (
                      <div
                        className="dropdown-item block px-4 py-2 text-xs 2xl:text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        key={key}
                        
                        onClick={() => {
                          this.props.onChange(opt.key)
                          this.setState({ label: opt.label })
                        }}
                      >
                        {opt.label}
                      </div>
                    )
                  })}
                </>
              </Dropdown.DropdownButton>
            )
        ) : (
            ""
          )
        }
        {
          this.props.subContent != null ? (
            <span className="form-text text-muted">{this.props.subContent}</span>
          ) : (
              ""
            )
        }
      </>
    )
  }
}

export default withTranslation()(Select)
