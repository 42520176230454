import React, { useState } from "react";
import Aside from './Aside';
import { Outlet } from "react-router-dom";
import { useContext } from 'react';
import { ThemeContext } from '../../App';
import "./changetabeffect.css"

export const FormTitleContext = React.createContext();

export default function Global() {
  const [formTitle, setFormTitle] = useState(null);
  const theme = useContext(ThemeContext);
  return <div className="typography flex flex-col h-screen overflow-hidden" style={{ fontFamily: "Inter" }}>
    <FormTitleContext.Provider value={[formTitle, setFormTitle]}>
      <Aside theme={theme} />
      <div className="flex-1 overflow-y-scroll">
        <Outlet />
      </div>
    </FormTitleContext.Provider>
  </div>
}

